"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateThemeDocument = exports.GetThemeDocument = exports.UpdateStoreSettingsDocument = exports.GetSplashScreenDocument = exports.GetSigningDocument = exports.GetPushProvisioningDocument = exports.GetLocaleDocument = exports.GetIosImagesDocument = exports.GetCventReleaseVersionsDocument = exports.BuildsStartDocument = exports.BuildsCancelDocument = exports.GetBuildQueueConfigurationDocument = exports.UpdateBranchLinkDataDocument = exports.GetBranchLinkDataDocument = exports.GetBranchConfigurationDocument = exports.GetAppsDocument = exports.UpdateApplicationPreferencesDocument = exports.GetApplicationPreferencesDocument = exports.GetApplicationBuildsDocument = exports.GetOverviewPageDocument = exports.UpdateAppDetailsPageDocument = exports.UpdateIosImagesDocument = exports.UpdateAndroidImagesDocument = exports.UpdateSplashScreenDocument = exports.UpdateAppDetailsDocument = exports.GetAppDetailsDocument = exports.GetAppDocument = exports.GetAndroidImagesDocument = exports.MediaLibraryImageFragmentFragmentDoc = exports.ThemeHeaderShape = exports.TaskStatus = exports.Status = exports.SigningType = exports.PushNotificationType = exports.ProvisioningType = exports.PreferFormula = exports.Platform = exports.IosSigningType = exports.DistributionType = exports.DistributionBuildType = exports.CodeEnvironmentType = exports.BuildTypes = exports.BuildType = exports.BuildQueueStatuses = void 0;
var BuildQueueStatuses;
(function (BuildQueueStatuses) {
    BuildQueueStatuses["Available"] = "Available";
    BuildQueueStatuses["Full"] = "Full";
    BuildQueueStatuses["Unavailable"] = "Unavailable";
})(BuildQueueStatuses = exports.BuildQueueStatuses || (exports.BuildQueueStatuses = {}));
var BuildType;
(function (BuildType) {
    BuildType["AndroidAab"] = "AndroidAAB";
    BuildType["AndroidApk"] = "AndroidAPK";
    BuildType["Iosipa"] = "IOSIPA";
    BuildType["IosSimulator"] = "IOSSimulator";
})(BuildType = exports.BuildType || (exports.BuildType = {}));
var BuildTypes;
(function (BuildTypes) {
    BuildTypes["AndroidAab"] = "AndroidAAB";
    BuildTypes["AndroidApk"] = "AndroidAPK";
    BuildTypes["Iosipa"] = "IOSIPA";
    BuildTypes["IosSimulator"] = "IOSSimulator";
})(BuildTypes = exports.BuildTypes || (exports.BuildTypes = {}));
var CodeEnvironmentType;
(function (CodeEnvironmentType) {
    CodeEnvironmentType["Dev"] = "Dev";
    CodeEnvironmentType["Internal"] = "Internal";
    CodeEnvironmentType["Production"] = "Production";
})(CodeEnvironmentType = exports.CodeEnvironmentType || (exports.CodeEnvironmentType = {}));
var DistributionBuildType;
(function (DistributionBuildType) {
    DistributionBuildType["EnterpriseAndroidAab"] = "Enterprise_AndroidAAB";
    DistributionBuildType["EnterpriseAndroidApk"] = "Enterprise_AndroidAPK";
    DistributionBuildType["EnterpriseIosipa"] = "Enterprise_IOSIPA";
    DistributionBuildType["IosSimulator"] = "IOSSimulator";
    DistributionBuildType["StoreAndroidAab"] = "Store_AndroidAAB";
    DistributionBuildType["StoreAndroidApk"] = "Store_AndroidAPK";
    DistributionBuildType["StoreIosipa"] = "Store_IOSIPA";
})(DistributionBuildType = exports.DistributionBuildType || (exports.DistributionBuildType = {}));
var DistributionType;
(function (DistributionType) {
    DistributionType["Enterprise"] = "Enterprise";
    DistributionType["Store"] = "Store";
})(DistributionType = exports.DistributionType || (exports.DistributionType = {}));
var IosSigningType;
(function (IosSigningType) {
    IosSigningType["CventManaged"] = "CventManaged";
    IosSigningType["CventManagedInternal"] = "CventManagedInternal";
})(IosSigningType = exports.IosSigningType || (exports.IosSigningType = {}));
var Platform;
(function (Platform) {
    Platform["Android"] = "Android";
    Platform["Ios"] = "IOS";
})(Platform = exports.Platform || (exports.Platform = {}));
var PreferFormula;
(function (PreferFormula) {
    PreferFormula["Dark"] = "Dark";
    PreferFormula["Default"] = "Default";
    PreferFormula["Light"] = "Light";
})(PreferFormula = exports.PreferFormula || (exports.PreferFormula = {}));
var ProvisioningType;
(function (ProvisioningType) {
    ProvisioningType["AdHoc"] = "AdHoc";
    ProvisioningType["InHouse"] = "InHouse";
    ProvisioningType["Store"] = "Store";
})(ProvisioningType = exports.ProvisioningType || (exports.ProvisioningType = {}));
var PushNotificationType;
(function (PushNotificationType) {
    PushNotificationType["Certificate"] = "Certificate";
    PushNotificationType["Token"] = "Token";
})(PushNotificationType = exports.PushNotificationType || (exports.PushNotificationType = {}));
var SigningType;
(function (SigningType) {
    SigningType["Cvent"] = "Cvent";
    SigningType["External"] = "External";
})(SigningType = exports.SigningType || (exports.SigningType = {}));
var Status;
(function (Status) {
    Status["Cancelled"] = "Cancelled";
    Status["Cancelling"] = "Cancelling";
    Status["Complete"] = "Complete";
    Status["Error"] = "Error";
    Status["InProgress"] = "InProgress";
    Status["Processing"] = "Processing";
    Status["Queued"] = "Queued";
})(Status = exports.Status || (exports.Status = {}));
var TaskStatus;
(function (TaskStatus) {
    TaskStatus["Done"] = "Done";
    TaskStatus["Optional"] = "Optional";
    TaskStatus["Required"] = "Required";
})(TaskStatus = exports.TaskStatus || (exports.TaskStatus = {}));
var ThemeHeaderShape;
(function (ThemeHeaderShape) {
    ThemeHeaderShape["Standard"] = "Standard";
    ThemeHeaderShape["Wave"] = "Wave";
})(ThemeHeaderShape = exports.ThemeHeaderShape || (exports.ThemeHeaderShape = {}));
exports.MediaLibraryImageFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MediaLibraryImageFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaLibraryImage' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                ]
            }
        }
    ]
};
exports.GetAndroidImagesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getAndroidImages' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appDetails' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'androidImages' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'appIcon' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'FragmentSpread',
                                                                        name: { kind: 'Name', value: 'MediaLibraryImageFragment' }
                                                                    },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'featureGraphic' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'FragmentSpread',
                                                                        name: { kind: 'Name', value: 'MediaLibraryImageFragment' }
                                                                    },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MediaLibraryImageFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaLibraryImage' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                ]
            }
        }
    ]
};
exports.GetAppDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getApp' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.GetAppDetailsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getAppDetails' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appDetails' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'iOSBundleId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'androidBundleId' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'androidImages' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'appIcon' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaLibraryImageFragment' } }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'featureGraphic' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaLibraryImageFragment' } }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'iOSImages' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'appIcon' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaLibraryImageFragment' } }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'storeIcon' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaLibraryImageFragment' } }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'theme' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'actionColor' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'headerShape' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                                    ]
                                                }
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'storeSettings' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'appleAccountName' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'androidAccountName' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'storeName' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'storeDescription' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'storeShortDescription' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'storeKeywords' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'supportUrl' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'appleTeamId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sellerName' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'mainContact' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } }
                                                    ]
                                                }
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MediaLibraryImageFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaLibraryImage' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                ]
            }
        }
    ]
};
exports.UpdateAppDetailsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateAppDetails' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppDetailsInput' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'appDetails' },
                        name: { kind: 'Name', value: 'updateAppDetails' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'iOSBundleId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'androidBundleId' } }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.UpdateSplashScreenDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSplashScreen' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaLibraryImageInput' } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'splashScreen' },
                        name: { kind: 'Name', value: 'updateSplashScreen' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaLibraryImageFragment' } }]
                        }
                    }
                ]
            }
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MediaLibraryImageFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaLibraryImage' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                ]
            }
        }
    ]
};
exports.UpdateAndroidImagesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateAndroidImages' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'AndroidImagesInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'androidImages' },
                        name: { kind: 'Name', value: 'updateAndroidImages' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appIcon' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaLibraryImageFragment' } }]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'featureGraphic' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaLibraryImageFragment' } }]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MediaLibraryImageFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaLibraryImage' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                ]
            }
        }
    ]
};
exports.UpdateIosImagesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateIOSImages' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'IOSImagesInput' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'iOSImages' },
                        name: { kind: 'Name', value: 'updateIOSImages' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appIcon' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaLibraryImageFragment' } }]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'storeIcon' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaLibraryImageFragment' } }]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MediaLibraryImageFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaLibraryImage' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                ]
            }
        }
    ]
};
exports.UpdateAppDetailsPageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateAppDetailsPage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'appDetailsInput' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppDetailsInput' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeSettingsInput' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreSettingsInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'appDetails' },
                        name: { kind: 'Name', value: 'updateAppDetails' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'appDetailsInput' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'iOSBundleId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'androidBundleId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'storeSettings' },
                        name: { kind: 'Name', value: 'updateStoreSettings' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeSettingsInput' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'appleAccountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'androidAccountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'storeName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'storeDescription' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'storeShortDescription' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'storeKeywords' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'supportUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'appleTeamId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'sellerName' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mainContact' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'email' } }
                                        ]
                                    }
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.GetOverviewPageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getOverviewPage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicationPreferences' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'distributionType' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'android' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'signingType' } }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'iOS' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'signingType' } }
                                                    ]
                                                }
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'preferencesSet' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'environmentParameters' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'codeEnvironment' } }]
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'iOS' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'signing' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'pushNotification' } }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'android' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'signing' } }
                                                    ]
                                                }
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'appDetails' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'signingType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'splashScreen' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'theme' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.GetApplicationBuildsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getApplicationBuilds' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildInput' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BuildInput' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicationBuilds' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'buildInput' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildInput' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'previousToken' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'builds' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'buildInput' },
                                            value: { kind: 'Variable', name: { kind: 'Name', value: 'buildInput' } }
                                        }
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'buildId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'platform' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'buildType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'distributionType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'distributionBuildType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'signingType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'createdDateTime' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'formattedCreatedDateTime' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'updatedDateTime' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'formattedUpdatedDateTime' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'cventVersion' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'downloadable' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'errorDescription' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'buildJobId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'buildRequestId' } }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.GetApplicationPreferencesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getApplicationPreferences' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'applicationPreferences' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'distributionType' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'android' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'signingType' } }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'iOS' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'signingType' } }
                                                    ]
                                                }
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'preferencesSet' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'environmentParameters' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'codeEnvironment' } }]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.UpdateApplicationPreferencesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateApplicationPreferences' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApplicationPreferencesInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'applicationPreferences' },
                        name: { kind: 'Name', value: 'updateApplicationPreferences' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'distributionType' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'android' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'signingType' } }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'iOS' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'signingType' } }
                                        ]
                                    }
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'preferencesSet' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'environmentParameters' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'codeEnvironment' } }]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.GetAppsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getApps' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'apps' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.GetBranchConfigurationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getBranchConfiguration' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'branchConfiguration' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'branchKey' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'branchDomain' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'branchAlternateDomain' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.GetBranchLinkDataDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getBranchLinkData' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'branchLinkData' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'iOSUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'androidUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'sha256' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.UpdateBranchLinkDataDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateBranchLinkData' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'BranchLinkDataInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'branchLinkData' },
                        name: { kind: 'Name', value: 'updateBranchLinkData' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'iOSUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'androidUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'sha256' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.GetBuildQueueConfigurationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getBuildQueueConfiguration' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'buildQueueConfig' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }]
                        }
                    }
                ]
            }
        }
    ]
};
exports.BuildsCancelDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'buildsCancel' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BuildsCancelInput' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'buildsCancel' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
                            }
                        ]
                    }
                ]
            }
        }
    ]
};
exports.BuildsStartDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'buildsStart' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BuildsStartInput' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'buildsStart' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
                            }
                        ]
                    }
                ]
            }
        }
    ]
};
exports.GetCventReleaseVersionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getCventReleaseVersions' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cventReleaseVersions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'versions' } }]
                        }
                    }
                ]
            }
        }
    ]
};
exports.GetIosImagesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getIOSImages' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appDetails' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'iOSImages' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'appIcon' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'FragmentSpread',
                                                                        name: { kind: 'Name', value: 'MediaLibraryImageFragment' }
                                                                    },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'storeIcon' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'FragmentSpread',
                                                                        name: { kind: 'Name', value: 'MediaLibraryImageFragment' }
                                                                    },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MediaLibraryImageFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaLibraryImage' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                ]
            }
        }
    ]
};
exports.GetLocaleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getLocale' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                        directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }]
                    }
                ]
            }
        }
    ]
};
exports.GetPushProvisioningDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getPushProvisioning' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pushProvisioning' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'certificate' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'expiration' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'organizationalUnit' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'fingerprint' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'sha256' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'sha1' } }
                                                                ]
                                                            }
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'token' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'uploadDate' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'keyId' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.GetSigningDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getSigning' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'signing' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'iOS' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'provisioningProfile' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'appIdName' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'applicationIdentifierPrefix' } },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'certificateFingerprint' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'sha256' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'sha1' } }
                                                                            ]
                                                                        }
                                                                    },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'provisioningType' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'provisioningName' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'teamIdentifier' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'signingType' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'certificate' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'expiration' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'organizationalUnit' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'commonName' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'fingerprint' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'sha256' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'sha1' } }
                                                                            ]
                                                                        }
                                                                    },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'android' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'keystore' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'expiration' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'organizationalUnit' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'commonName' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'fingerprint' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'sha256' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'sha1' } }
                                                                            ]
                                                                        }
                                                                    },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.GetSplashScreenDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getSplashScreen' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appDetails' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'splashScreen' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaLibraryImageFragment' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MediaLibraryImageFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaLibraryImage' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                ]
            }
        }
    ]
};
exports.UpdateStoreSettingsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateStoreSettings' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreSettingsInput' } }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'storeSettings' },
                        name: { kind: 'Name', value: 'updateStoreSettings' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'appleAccountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'androidAccountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'storeName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'storeDescription' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'storeShortDescription' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'storeKeywords' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'supportUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'appleTeamId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'sellerName' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mainContact' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'email' } }
                                        ]
                                    }
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.GetThemeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getTheme' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'app' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appDetails' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'theme' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'actionColor' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'headerShape' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'safeColorMode' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'preferFormula' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
exports.UpdateThemeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateTheme' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemeInput' } } }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'theme' },
                        name: { kind: 'Name', value: 'updateTheme' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actionColor' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'headerShape' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'safeColorMode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'preferFormula' } }
                            ]
                        }
                    }
                ]
            }
        }
    ]
};
